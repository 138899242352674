/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { TASK, SUBTASK } from '@core/services/task'

/**
 * State
 */
const state = {
  isUploading: false,
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  contentTask: null,
  allContentTasks: [],
  allTaskFrequencies: [],
  allTaskTypes: [],
  allSubTaskTypes: [],
}
/**
 * getters
 */
const getters = {
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  isLoading: state => state.isLoading,
  getAllContentTasks: state => state.allContentTasks,
  getAllTaskFrequencies: state => state.allTaskFrequencies,
  getAllTaskTypes: state => state.allTaskTypes,
  getAllSubTaskTypes: state => state.allSubTaskTypes,
  getContentTask: state => state.contentTask,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_CONTENT_TASKS: (state, data) => {
    state.allContentTasks = data
  },
  SET_TASK_FREQUENCIES: (state, data) => {
    state.allTaskFrequencies = data
  },
  SET_TASK_TYPES: (state, data) => {
    state.allTaskTypes = data
  },
  SET_SUB_TASK_TYPES: (state, data) => {
    state.allSubTaskTypes = data
  },
  SET_CONTENT_TASK(state, data) {
    state.contentTask = data
  },
}
/**
 * actions
 */

const actions = {

  /**
   * Create new Task
   * @returns {Promise<unknown>}
   */
  async createTask({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(TASK.BASE, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
 * Update Task
 * @returns {Promise<unknown>}
 */
  async updateTask({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(TASK.BY_ID(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
 * Delete Task
 * @returns {Promise<unknown>}
 */
  async deleteTask({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(TASK.BY_ID(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },

  /**
   * get Content Tasks by ID
   * @returns {Promise<unknown>}
   */
  async fetchContentTaskByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TASK.CONTENT_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_CONTENT_TASKS', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Tasks by ID
   * @returns {Promise<unknown>}
   */
  async fetchTaskByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TASK.BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_CONTENT_TASK', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Task Types
   * @returns {Promise<unknown>}
   */
  async fetchTaskTypes({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TASK.TYPE)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_TASK_TYPES', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Tasks Frequencies
   * @returns {Promise<unknown>}
   */
  async fetchTaskFrequencies({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TASK.FREQUENCY)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_TASK_FREQUENCIES', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get SubTask Types
   * @returns {Promise<unknown>}
   */
  async fetchSubTaskTypes({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(SUBTASK.TYPE)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_SUB_TASK_TYPES', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

  /**
   * Create new Task
   * @returns {Promise<unknown>}
   */
  async createSubTask({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(SUBTASK.BASE, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
 * Update Sub Task
 * @returns {Promise<unknown>}
 */
  async updateSubTask({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(SUBTASK.BY_ID(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
 * Delete Sub Task
 * @returns {Promise<unknown>}
 */
  async deleteSubTask({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(SUBTASK.BY_ID(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
