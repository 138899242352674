export default [
  {
    path: '/content/media',
    name: 'media-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/media/media-list/MediaList.vue'),
    meta: {
      pageTitle: 'Media',
      breadcrumb: [
        {
          text: 'Media List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/media-configuration',
    name: 'media-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/media/media-configuration/MediaConfiguration.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/content/media-configuration/:id',
    name: 'media-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/media/media-configuration/MediaConfiguration.vue'),
    meta: {
      breadcrumb: [],
    },
  },
]
