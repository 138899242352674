/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved, import/named
import { TEAM } from '@core/services/team'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  allTeams: [],
}
/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  getAllTeams: state => state.allTeams,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_ALL_TEAMS: (state, data) => {
    state.allTeams = data
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Create new Team
   * @returns {Promise<unknown>}
   */
  async createTeam({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(TEAM.BASE, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Team User
   * @returns {Promise<unknown>}
   */
  async createTeamUser({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(TEAM.USER_ADD, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * update Team
   * @returns {Promise<unknown>}
   */
  async updateTeam({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(TEAM.BY_ID(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },

  /**
   * delete Team
   * @returns {Promise<unknown>}
   */
  async deleteTeam({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(TEAM.BY_ID(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Team User
   * @returns {Promise<unknown>}
   */
  async deleteTeamUser({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(TEAM.USER_DELETE(data.teamId, data.userId))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Teams
   * @returns {Promise<unknown>}
   */
  async fetchAllTeams({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TEAM.PROJECT_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_TEAMS', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
