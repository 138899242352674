import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  congratulations: {
    name: 'John',
    saleToday: '97800',
  },
  statisticsItems: [
    {
      icon: 'AwardIcon',
      color: 'light-primary',
      title: '230k',
      subtitle: 'Games',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'UserIcon',
      color: 'light-info',
      title: '8.549k',
      subtitle: 'Players',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'LayoutIcon',
      color: 'light-danger',
      title: '1.423k',
      subtitle: 'Companies',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'FileTextIcon',
      color: 'light-success',
      title: '300',
      subtitle: 'Templates',
      customClass: '',
    },
  ],
  statisticsOrder: {
    series: [
      {
        name: '2023',
        data: [45, 85, 65, 45, 65],
      },
    ],
  },
  statisticsProfit: {
    series: [
      {
        data: [0, 20, 5, 30, 15, 45],
      },
    ],
  },
  earningsChart: {
    series: [53, 16, 31],
  },
  revenue: {
    years: ['2023', '2022', '2021'],
    price: '25,851',
    budget: '56,800',
    revenueReport: {
      series: [
        {
          name: 'Earning',
          data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
        },
      ],
    },
    budgetChart: {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
        },
      ],
    },
  },
  transactionData: [
    {
      mode: 'Kundkontakter',
      types: 'AFRY Säljutveckling vår',
      avatar: 'ActivityIcon',
      avatarVariant: 'light-primary',
      payment: '74',
      deduction: true,
    },
    {
      mode: 'Måndagsmöte',
      types: 'Samhall LinkedIn Game',
      avatar: 'CheckIcon',
      avatarVariant: 'light-success',
      payment: '480',
      deduction: false,
    },
    {
      mode: 'Ta upp problem',
      types: 'Ledarskapsträning Game-OBM',
      avatar: 'TrendingUpIcon',
      avatarVariant: 'light-danger',
      payment: '480',
      deduction: false,
    },
    {
      mode: 'Förberedelse',
      types: 'Hävstångsledarskap',
      avatar: 'TrendingDownIcon',
      avatarVariant: 'light-warning',
      payment: '23',
      deduction: true,
    },
    {
      mode: ' Lyssna aktivt',
      types: 'MPM Konsult',
      avatar: 'TrendingUpIcon',
      avatarVariant: 'light-info',
      payment: '98',
      deduction: false,
    },
  ],
}
/* eslint-disable global-require */
mock.onGet('/ecommerce/data').reply(() => [200, data])
