export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/overview',
    name: 'dashboard-overview',
    component: () => import('@/views/company/company-list/CompanyList.vue'),
  },
  {
    path: '/dashboard/detail-view',
    name: 'dashboard-detail-view',
    component: () => import('@/views/company/company-list/CompanyView.vue'),
  },
]
