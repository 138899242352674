export default [
  {
    path: '/content/task',
    name: 'task-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-list/TaskList.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/content/task/:id',
    name: 'task-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-list/TaskList.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/content/task-configuration',
    name: 'task-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-configuration/TaskConfiguration.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/content/task-configuration/:id',
    name: 'task-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-configuration/TaskConfiguration.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/content/sub-task-configuration/:id',
    name: 'sub-task-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/task/task-configuration/SubTaskConfiguration.vue'),
    meta: {
      breadcrumb: [],
    },
  },
]
