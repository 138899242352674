/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { GAME } from '@core/services/game'

/**
 * State
 */
const state = {
  isCreating: false,
  isLoading: false,
  allGames: [],
  allGameCharities: [],
  allCharities: [],
  availableUsers: [],
  paginations: '',
  isDeleting: false,
  game: '',
}
/**
 * getters
 */
const getters = {
  isCreating: state => state.isCreating,
  isLoading: state => state.isLoading,
  isDeleting: state => state.isDeleting,
  getAllGames: state => state.allGames,
  getAllGameCharities: state => state.allGameCharities,
  getAllCharities: state => state.allCharities,
  getAvailableUsers: state => state.availableUsers,
  getPagination: state => state.paginations,
  getGame: state => state.game,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_ALL_GAMES: (state, data) => {
    state.allGames = data
  },
  SET_ALL_GAME_CHARITIES: (state, data) => {
    state.allGameCharities = data
  },
  SET_ALL_CHARITIES: (state, data) => {
    state.allCharities = data
  },
  SET_ALL_AVAILABLE_USERS: (state, data) => {
    state.availableUsers = data
  },
  SET_GAME: (state, data) => {
    state.game = data
  },
  SET_PAGINATIONS: (state, data) => {
    state.paginations = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * Create new Competition
   * @returns {Promise<unknown>}
   */
  async createGame({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.ADD, { competition: data })
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create Game Analyze
   * @returns {Promise<unknown>}
   */
  async createGameAnalyze({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.GAME_ANALYZE_BY_ID(data.ID))
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * update Competition
   * @returns {Promise<unknown>}
   */
  async updateGame({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.UPDATE_GAME, { competition: data })
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete Game
   * @returns {Promise<unknown>}
   */
  async deleteGame({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(GAME.DELETE(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * copy Task
   * @returns {Promise<unknown>}
   */
  async copyTask({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.COPY_TASK, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * copy Kpi
   * @returns {Promise<unknown>}
   */
  async copyKpi({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.COPY_KPI, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * copy Group
   * @returns {Promise<unknown>}
   */
  async copyGroup({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.COPY_GROUP, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Competition Charity
   * @returns {Promise<unknown>}
   */
  async createCompetitionCharity({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.ADD_CHARITY, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Sent Invitation Email
   * @returns {Promise<unknown>}
   */
  async sendInvitationEmail({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.SEND_INVITATION_EMAIL, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Group
   * @returns {Promise<unknown>}
   */
  async createGroup({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.ADD_GROUP, { group: data })
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Group User
   * @returns {Promise<unknown>}
   */
  async createGroupUser({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.ADD_GROUP_USER, { data })
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * update Group
   * @returns {Promise<unknown>}
   */
  async updateGroup({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.UPDATE_GROUP, { group: data })
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Competition Charity
   * @returns {Promise<unknown>}
   */
  async deleteCompetitionCharity({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(GAME.DELETE_CHARITY_ID(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete Group
   * @returns {Promise<unknown>}
   */
  async deleteGroup({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GAME.DELETE_GROUP, data)
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Games
   * @returns {Promise<unknown>}
   */
  async fetchAllGames({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GAME.GET_ALL)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GAMES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all  Charities
   * @returns {Promise<unknown>}
   */
  async fetchAllCharities({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GAME.GET_CHARITY)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CHARITIES', response.data.data.result)
          resolve(response.data.data.result)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Available users
   * @returns {Promise<unknown>}
   */
  async fetchAvailableUsers({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GAME.GET_AVAILABLE_USERS(data.id, data.isAll))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_AVAILABLE_USERS', response)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Game Charities
   * @returns {Promise<unknown>}
   */
  async fetchAllGameCharities({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GAME.GET_COMPETITION_CHARITY(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GAME_CHARITIES', response.data.data.result)
          resolve(response.data.data.result)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Game by id
   * @returns {Promise<unknown>}
   */
  async fetchGameByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GAME.GET_SINGLE(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_GAME', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Group Result by ID
   * @returns {Promise<unknown>}
   */
  async fetchGroupResultByID({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GAME.GET_GROUP_RESULTS_BY_ID(data.id, data.params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
