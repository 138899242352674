/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { GOAL } from '@core/services/goal'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  allGoals: [],
  allGoalSubjects: [],
  allGoalPeriods: [],
  allGoalTypes: [],

}
/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  getAllGoals: state => state.allGoals,
  getGoalSubjects: state => state.allGoalSubjects,
  getGoalPeriods: state => state.allGoalPeriods,
  getGoalTypes: state => state.allGoalTypes,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_ALL_GOALS: (state, data) => {
    state.allGoals = data
  },
  SET_ALL_GOAL_SUBJECT: (state, data) => {
    state.allGoalSubjects = data
  },
  SET_ALL_GOAL_PERIOD: (state, data) => {
    state.allGoalPeriods = data
  },
  SET_ALL_GOAL_TYPES: (state, data) => {
    state.allGoalTypes = data
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Create new Goal
   * @returns {Promise<unknown>}
   */
  async createGoal({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(GOAL.BASE, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * update Goal
   * @returns {Promise<unknown>}
   */
  async updateGoal({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(GOAL.BY_ID(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },

  /**
   * delete Goal
   * @returns {Promise<unknown>}
   */
  async deleteGoal({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(GOAL.BY_ID(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },

  /**
   * get all Goals
   * @returns {Promise<unknown>}
   */
  async fetchAllGoals({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GOAL.CONTENT_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GOALS', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Goal Subject
   * @returns {Promise<unknown>}
   */
  async fetchGoalSubject({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GOAL.SUBJECT)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GOAL_SUBJECT', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Goal Type
   * @returns {Promise<unknown>}
   */
  async fetchGoalType({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GOAL.TYPE)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GOAL_TYPES', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Goal Type
   * @returns {Promise<unknown>}
   */
  async fetchGoalPeriod({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GOAL.PERIOD)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GOAL_PERIOD', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
