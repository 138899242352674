import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  pricing: {
    basicPlan: {
      title: 'Basic',
      img: require('@/assets/images/illustration/Pot1.svg'),
      subtitle: 'A simple start for everyone',
      monthlyPrice: 19,
      yearlyPlan: {
        perMonth: 20,
        totalAnual: 150,
      },
      planBenefits: [
        '1 Game per month',
        'Up to 5 users',
        'Admin Controls',
        'Reports and insights',
      ],
      popular: false,
    },
    standardPlan: {
      title: 'Standard',
      img: require('@/assets/images/illustration/Pot2.svg'),
      subtitle: 'For small to medium businesses',
      monthlyPrice: 49,
      yearlyPlan: {
        perMonth: 40,
        totalAnual: 480,
      },
      planBenefits: [
        '10 Games per month',
        'Up to 100 users',
        'Apps and integrations',
        'Admin Controls',
        'Reports and insights',
        'Support Services',
        'Stripe integration'
      ],
      popular: true,
    },
    enterprisePlan: {
      title: 'Enterprise',
      img: require('@/assets/images/illustration/Pot3.svg'),
      subtitle: 'Solution for big organizations',
      monthlyPrice: 99,
      yearlyPlan: {
        perMonth: 80,
        totalAnual: 960,
      },
      planBenefits: [
        'Unlimited Games',
        'Up to 2000 users',
        'Apps and integrations',
        'Admin Controls',
        'Reports and insights',
        '24/7 Support Services',
        'Stripe integration'
      ],
      popular: false,
    },
    qandA: [
      {
        question: 'Does my subscription automatically renew?',
        ans:
          'After the free trial period you decide if you wish to continue. Then the subscription will get automatically renewed monthly, based on the package you have selected for you/your company.',
      },
      {
        question: 'Is there a way to test Bisevo platform out before purchasing it?',
        ans:
          'Yes! We do have a 14-day free trial which allows you to test all the features available for your selected package.',
      },
      {
        question: 'Is it possible to make advancements to the content (setup templates), when moving to the next stage/milestone?',
        ans:
          'As an admin user, you are always allowed to update the content as required to reach your milestone. Your content will automatically update when you are moving to another stage.',
      },
      {
        question: 'What are options?',
        ans:
          'Options are additional customizations that are available for companies to select and purchase separately according to their requirement. If you need more information on this or need to purchase an option, please click on the option and contact Bisevo.',
      },
    ],
  },
}
/* eslint-disable global-require */
mock.onGet('/pricing/data').reply(() => [200, data.pricing])
