/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { httpUpload, tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { USER } from '@core/services/user'

/**
 * State
 */
const state = {
  isUploading: false,
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  allUsers: [],
  allUpoadGames: [],
  allUserBatch: [],
  allUserNotifications: [],
  allUserRoles: [],
  allUserUploads: [],
  userPerformedTasks: [],
  userGameGroups: [],
  user: '',
}
/**
 * getters
 */
const getters = {
  getAllUsers: state => state.allUsers,
  getAllUpoadlGames: state => state.allUpoadGames,
  getAllUserBatch: state => state.allUserBatch,
  getAllUserUploads: state => state.allUserUploads,
  getAllUserRoles: state => state.allUserRoles,
  getUserNotifications: state => state.allUserNotifications,
  getUserPerformedTasks: state => state.userPerformedTasks,
  getUserGameGroups: state => state.userGameGroups,
  isUploading: state => state.isUploading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  isLoading: state => state.isLoading,
  getUser: state => state.user,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_UPLOADING: (state, data) => {
    state.isUploading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_ALL_USERS: (state, data) => {
    state.allUsers = data
  },
  SET_ALL_UPLOAD_GAMES: (state, data) => {
    state.allUpoadGames = data
  },
  SET_ALL_USER_BATCH: (state, data) => {
    state.allUserBatch = data
  },
  SET_ALL_USER_UPLOAD: (state, data) => {
    state.allUserUploads = data
  },
  SET_ALL_USER_NOTIFICATIONS: (state, data) => {
    state.allUserNotifications = data
  },
  SET_USER_PERFORMED_TASKS: (state, data) => {
    state.userPerformedTasks = data
  },
  SET_USER_GAME_GROUPS: (state, data) => {
    state.userGameGroups = data
  },
  SET_ALL_USER_ROLES: (state, data) => {
    state.getAllUserRoles = data
  },
  SET_USER: (state, data) => {
    state.user = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * Create User
   * @returns {Promise<unknown>}
   */
  async createUser({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(USER.BASE, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update User
   * @returns {Promise<unknown>}
   */
  async updateUser({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(USER.BY_ID(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
  * Delete a User by ID
  * @param {Object} context
  * @param {number} id
  * @returns {Promise}
  */
  async deleteUser({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(USER.BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
 * Reset Password
 * @returns {Promise<unknown>}
 */
  async resetPassword({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(USER.RESET_PASSWORD, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
 * user logout
 * @returns {Promise<unknown>}
 */
  async userLogout({ commit }) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(USER.LOGOUT)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get User by ID
   * @returns {Promise<unknown>}
   */
  async fetchUserByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_USER', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get User
   * @returns {Promise<unknown>}
   */
  async fetchUserByEmail({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.EMAIL_EXISTS(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_USER', response.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get User Role
   * @returns {Promise<unknown>}
   */
  async fetchUserRoles({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.GET_USER_ROLE)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_USER_ROLES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

  /**
   * get all  Users
   * @returns {Promise<unknown>}
   */
  async fetchAllUsers({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.BASE)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_USERS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

  /**
   * send Email to User
   * @returns {Promise<unknown>}
   */
  async sendEmailUser({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(USER.SEND_EMAIL_USER, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Upload users
   * @returns {Promise<unknown>}
   */
  async uploadUsers({ commit }, data) {
    commit('SET_IS_UPLOADING', true)
    return await new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('companyID', data.companyID)
      formData.append('file', data.file)
      if (data.template_id != null && data.template_id !== undefined) {
        formData.append('startdate', data.startdate)
        formData.append('enddate', data.enddate)
        formData.append('template_id', data.template_id)
        formData.append('category_id', data.category_id)
        formData.append('visionstatement', data.visionstatement)
      }
      httpUpload()
        .post(USER.USER_UPLOAD, formData)
        .then(response => {
          commit('SET_IS_UPLOADING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_UPLOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Upload Confirmation
   * @returns {Promise<unknown>}
   */
  async uploadUserConfirmation({ commit }, id) {
    commit('SET_IS_UPLOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(USER.USER_UPLOAD_CONFIRMATION(id))
        .then(response => {
          commit('SET_IS_UPLOADING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_UPLOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete User Batch
   * @returns {Promise<unknown>}
   */
  async deleteUserBatch({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(USER.DELETE_USER_BATCH(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete User Upload Batch
   * @returns {Promise<unknown>}
   */
  async deleteUserUpload({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(USER.DELETE_USER_UPLOAD(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },

  /**
   * get Game by Batch ID
   * @returns {Promise<unknown>}
   */
  async fetchGameByBatchID({ commit }, params) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.GET_GAME_UPLOADS_BY_BATCH_ID(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_UPLOAD_GAMES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get User Batch
   * @returns {Promise<unknown>}
   */
  async fetchUserBatch({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.GET_USER_BATCH)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_USER_BATCH', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get User Uploads
   * @returns {Promise<unknown>}
   */
  async fetchUserUploads({ commit }, params) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.GET_USER_UPLOADS_BY_BATCH_ID(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_USER_UPLOAD', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Performed Tasks by UserID
   * @returns {Promise<unknown>}
   */
  async fetchPerformedTasksByUserID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.GET_USER_PERFORMED_TASKS_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_USER_PERFORMED_TASKS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Group & Game  by UserID
   * @returns {Promise<unknown>}
   */
  async fetchGroupGameByUserID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.GET_GROUP_GAME_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_USER_GAME_GROUPS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get User Notifications by UserID
   * @returns {Promise<unknown>}
   */
  async fetchUserNotificationsByUserID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.GET_USER_NOTIFICATIONS_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_USER_NOTIFICATIONS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * update User NotificationsBy ID
   * @returns {Promise<unknown>}
   */
  async updateUserNotificationsByID({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(USER.UPDATE_USER_NOTIFICATIONS_BY_ID(data.ID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Forgot Password
   * @returns {Promise<unknown>}
   */
  async forgotPassword({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(USER.FORGOT_PASSWORD, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get ResetPassword
   * @returns {Promise<unknown>}
   */
  async fetchResetPassword({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.GET_RESET_PASSWORD(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_USER', response)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Users by Company Id
   * @returns {Promise<unknown>}
   */
  async fetchUsersByCompanyId({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(USER.BY_COMPANY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_USERS', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
