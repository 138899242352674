/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
import { COMPANY } from '@core/services/company'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  isUploading: false,
  allCompanies: [],
  company: {},
}
/**
 * getters
 */
const getters = {
  getAllCompanies: state => state.allCompanies,
  getCompany: state => state.company,
  isLoading: state => state.isLoading,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, isLoading) => {
    state.isLoading = isLoading
  },
  SET_ALL_COMPANIES: (state, companies) => {
    state.allCompanies = companies
  },
  SET_COMPANY: (state, company) => {
    state.company = company
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * get all  Companies
   * @returns {Promise<unknown>}
   */
  async fetchAllCompanies({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(COMPANY.BASE)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_COMPANIES', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Fetch a single company by ID
   * @param {Object} context
   * @param {number} id
   * @returns {Promise}
   */
  async fetchCompanyById({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(COMPANY.BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_COMPANY', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

  /**
   * Create a new company
   * @returns {Promise<unknown>}
   */
  async createCompany({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('name', data.name)
      formData.append('email', data.email)
      formData.append('file', data.selectedFile)
      formData.append('addressLine1', data.addressLine1)
      formData.append('addressLine2', data.addressLine2)
      formData.append('postcode', data.postcode)
      formData.append('city', data.city)
      formData.append('country', data.country)
      formData.append('language', data.language)
      formData.append('packageId', data.packageId)
      formData.append('emailNotificationEnabled', data.emailNotificationEnabled)

      tokenRequest()
        .post(COMPANY.BASE, formData)
        .then(response => {
          commit('SET_IS_LOADING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

  /**
   * Update a company
   * @param {Object} context
   * @param {Object} companyData
   * @returns {Promise}
   */
  async updateCompany({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('name', data.name)
      formData.append('email', data.email)
      formData.append('file', data.selectedFile)
      formData.append('addressLine1', data.addressLine1)
      formData.append('addressLine2', data.addressLine2)
      formData.append('postcode', data.postcode)
      formData.append('city', data.city)
      formData.append('country', data.country)
      formData.append('language', data.language)
      formData.append('packageId', data.packageId)
      formData.append('emailNotificationEnabled', data.emailNotificationEnabled)
      tokenRequest()
        .put(COMPANY.BY_ID(data.id), formData)
        .then(response => {
          commit('SET_IS_LOADING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
  * Delete a company by ID
  * @param {Object} context
  * @param {number} id
  * @returns {Promise}
  */
  async deleteCompany({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(COMPANY.BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
