export default [
  {
    path: '/content/charity',
    name: 'charity-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/charity/charity-list/CharityList.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/content/charity/:id',
    name: 'charity-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/charity/charity-list/CharityList.vue'),
    meta: {
      breadcrumb: [],
    },
  },
]
