/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { ONBOARDING } from '@core/services/onboarding'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { GAME } from '@core/services/game'

/**
 * State
 */
const state = {
  isCreating: false,
  isLoading: false,
  salesLead: '',
  allSalesLeads: {
    data: [],
    paginator: {
      count: 0,
      pages: 0,
    },
  },
  allSetReports: {
    data: [],
    paginator: {
      count: 0,
      pages: 0,
    },
  },
}
/**
 * getters
 */
const getters = {
  isCreating: state => state.isCreating,
  isLoading: state => state.isLoading,
  getSalesLead: state => state.salesLead,
  getAllSalesLeads: state => state.allSalesLeads,
  getAllSetReports: state => state.allSetReports,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_SALE_LEAD: (state, data) => {
    state.salesLead = data
  },
  SET_ALL_SALES_LEAD: (state, data) => {
    state.allSalesLeads = data
  },
  SET_ALL_SET_REPORTS: (state, data) => {
    state.allSetReports = data
  },
}

/**
 * actions
 */
const actions = {
  /**
   * update Sales Lead
   * @returns {Promise<unknown>}
   */
  async updateSalesLead({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(ONBOARDING.GET_SALES_LEAD(data.ID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get all SalesLeads
   * @returns {Promise<unknown>}
   */
  async fetchAllSalesLeads({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(ONBOARDING.GET_ALL_SALES_LEADS)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_SALES_LEAD', {
            data: response.data.data.allResult,
            paginator: {
              count: response.data.data.count,
              pages: response.data.data.pages,
            },
          })
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Sales Lead by ID
   * @returns {Promise<unknown>}
   */
  async fetchSaleLeadByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(ONBOARDING.GET_SALES_LEAD(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_SALE_LEAD', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Report by ID
   * @returns {Promise<unknown>}
   */
  async fetchReportByID({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GAME.GET_GAME_REPORT(data.params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_SET_REPORTS', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
