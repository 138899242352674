export default [
  {
    path: '/companies/company-list',
    name: 'company-list',
    component: () => import('@/views/company/company-list/CompanyList.vue'),
    meta: {
      pageTitle: 'Company',
      breadcrumb: [
        {
          text: 'Company List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/companies/company-configuration',
    name: 'company-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/company/company-configuration/CompanyConfiguration.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Company Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/companies/company-configuration/:id',
    name: 'company-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/company/company-configuration/CompanyConfiguration.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Company Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/companies/company-view/:id',
    name: 'company-view-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/company/company-list/CompanyView.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/companies/company-detail-view',
    name: 'company-detail-view',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/company/company-list/CompanyView.vue'),
    meta: {
      pageTitle: 'Company',
      breadcrumb: [],
    },
  },
]
