/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { PROJECT } from '@core/services/project'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  isUploading: false,
  allProjects: [],
  project: '',
}
/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  isUploading: state => state.isUploading,
  getAllProjects: state => state.allProjects,
  getProject: state => state.project,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_ALL_PROJECTS: (state, data) => {
    state.allProjects = data
  },
  SET_PROJECT: (state, data) => {
    state.project = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * get all Projects
   * @returns {Promise<unknown>}
   */
  async fetchAllProjects({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(PROJECT.BASE)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_PROJECTS', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Project
   * @returns {Promise<unknown>}
   */
  async createProject({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(PROJECT.BASE, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Projects
   * @returns {Promise<unknown>}
   */
  async updateProject({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(PROJECT.BY_ID(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Projects
   * @returns {Promise<unknown>}
   */
  async deleteProject({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(PROJECT.BY_ID(data.id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * get Projects by ID
   * @returns {Promise<unknown>}
   */
  async fetchProjectByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(PROJECT.BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_PROJECT', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Projects by Company ID
   * @returns {Promise<unknown>}
   */
  async fetchProjectByCompanyID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(PROJECT.BY_COMPANY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_PROJECTS', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Project User
   * @returns {Promise<unknown>}
   */
  async createProjectUser({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(PROJECT.PROJECT_USER, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Project User
   * @returns {Promise<unknown>}
   */
  async updateProjectUser({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(PROJECT.DELETE_PROJECT_USER_BY_ID(data.userId, data.projectId), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Sent Project Invitation Email or SMS
   * @returns {Promise<unknown>}
   */
  async sendProjectInvitation({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(PROJECT.INVITATIONS(data.projectId), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Project User
   * @returns {Promise<unknown>}
   */
  async deleteProjectUser({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(PROJECT.DELETE_PROJECT_USER_BY_ID(data.userId, data.projectId))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * upload Company Users To Project
   * @returns {Promise<unknown>}
   */
  async uploadAllCompanyUsersToProject({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(PROJECT.ALL_COMPANY_USERS_PROJECTS(data))
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
