/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { CONTENT } from '@core/services/content'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  isUploading: false,
  questionCount: 0,
  question: '',
  content: '',
  allContents: [],
  allContentSets: [],
  allContentTypes: [],
  allContentFrequencies: [],
  allContentStatus: [],
  questions: [],
}
/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  isUploading: state => state.isUploading,
  getQuestions: state => state.questions,
  getQuestion: state => state.question,
  getContent: state => state.content,
  getAllContents: state => state.allContents,
  getAllContentSets: state => state.allContentSets,
  getAllContentTypes: state => state.allContentTypes,
  getAllContentFrequencies: state => state.allContentFrequencies,
  getAllContentStatus: state => state.allContentStatus,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_IS_UPLOADING: (state, data) => {
    state.isUploading = data
  },
  SET_QUESTIONS: (state, data) => {
    state.questions = data
  },
  SET_QUESTION: (state, data) => {
    state.question = data
  },
  SET_CONTENT: (state, data) => {
    state.content = data
  },
  SET_ALL_CONTENTS: (state, data) => {
    state.allContents = data
  },
  SET_ALL_CONTENT_SETS: (state, data) => {
    state.allContentSets = data
  },
  SET_ALL_CONTENT_TYPESS: (state, data) => {
    state.allContentTypes = data
  },
  SET_ALL_CONTENT_STATUS: (state, data) => {
    state.allContentStatus = data
  },
  SET_ALL_CONTENT_FREQUENCIES: (state, data) => {
    state.allContentFrequencies = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * get all Contents
   * @returns {Promise<unknown>}
   */
  async fetchAllContents({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.BASE)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CONTENTS', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Template Contents
   * @returns {Promise<unknown>}
   */
  async fetchAllTemplateContents({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.TEMPALTES)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CONTENTS', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Content
   * @returns {Promise<unknown>}
   */
  async createContent({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(CONTENT.BASE, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Content
   * @returns {Promise<unknown>}
   */
  async updateContent({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(CONTENT.BY_ID(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Content
   * @returns {Promise<unknown>}
   */
  async deleteContent({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(CONTENT.BY_ID(data.id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },

  /**
   * send Reminders Email
   * @returns {Promise<unknown>}
   */
  async sendRemindersEmail({ commit }, id) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(CONTENT.REMINDERS(id))
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get all ContentTypes
   * @returns {Promise<unknown>}
   */
  async fetchAllContentTypes({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.CONTENT_TYPES)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CONTENT_TYPESS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Content Frequencies
   * @returns {Promise<unknown>}
   */
  async fetchAllContentFrequencies({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.CONTENT_FREQUENCIES)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CONTENT_FREQUENCIES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Content Statuses
   * @returns {Promise<unknown>}
   */
  async fetchAllContentStatuses({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.CONTENT_STATUSES)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CONTENT_STATUS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Survey Question
   * @returns {Promise<unknown>}
   */
  async createSurveyQuestion({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(CONTENT.SURVEY_QUESTION, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create Survey Analyze
   * @returns {Promise<unknown>}
   */
  async createSurveyAnalyze({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(CONTENT.SURVEY_ANALYZE_BY_ID(data.ID))
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Survey User
   * @returns {Promise<unknown>}
   */
  async deleteSurveyUser({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(CONTENT.SURVEY_USER_BY_ID(data.ID))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },

  /**
   * get Content by ID
   * @returns {Promise<unknown>}
   */
  async fetchContentByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_CONTENT', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

  /**
   * get all Contents by Company ID
   * @returns {Promise<unknown>}
   */
  async fetchContentsByCompanyID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.BY_COMPANY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CONTENTS', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

  /**
   * get all Contents by Project ID
   * @returns {Promise<unknown>}
   */
  async fetchContentsByProjectID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.BY_PROJECT_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CONTENTS', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },

  /**
   * get Survey Reults by ID
   * @returns {Promise<unknown>}
   */
  async fetchSurveyResultByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.SURVEY_RESULT_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_CONTENT', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Survey Set by ID
   * @returns {Promise<unknown>}
   */
  async fetchSurveySetByID({ commit }, params) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.SURVEY_SET_BY_ID(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_SURVEY_SETS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Question
   * @returns {Promise<unknown>}
   */
  async createQuestion({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(CONTENT.QUESTION, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Question
   * @returns {Promise<unknown>}
   */
  async updateQuestion({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(CONTENT.QUESTION_BY_ID(data.questionID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get Question by ID
   * @returns {Promise<unknown>}
   */
  async fetchQuestionByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(CONTENT.QUESTION_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_QUESTION', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Question
   * @returns {Promise<unknown>}
   */
  async deleteQuestion({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(CONTENT.QUESTION_BY_ID(data.questionID))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Sent Content Invitation Email or SMS
   * @returns {Promise<unknown>}
   */
  async sendContentInvitation({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(CONTENT.INVITATIONS(data.contentId), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * upload Project Users To Survey
   * @returns {Promise<unknown>}
   */
  async uploadAllProjectUsersToSurvey({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(CONTENT.ALL_PROJECT_USERS_SURVEY(data))
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
