export default [
  {
    path: '/project/list',
    name: 'project-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/project/project-list/ProjectList.vue'),
    meta: {
      pageTitle: 'Project',
      breadcrumb: [
        {
          text: 'Project List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/project/project-configuration',
    name: 'project-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/project/project-configuration/ProjectConfiguration.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/project/project-configuration/:id',
    name: 'project-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/project/project-configuration/ProjectConfiguration.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/project/project-view/:id',
    name: 'project-view-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/project/project-configuration/ProjectView.vue'),
    meta: {
      breadcrumb: [],
    },
  },
]
