export default [
  {
    path: '/content/sets',
    name: 'set-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/sets/sets-list/SetList.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/content/sets/:id',
    name: 'set-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/sets/sets-list/SetList.vue'),
    meta: {
      breadcrumb: [],
    },
  },
]
