export default [
  {
    path: '/content/report',
    name: 'content-report',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/report-view/ReportView.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/saleslead-list',
    name: 'sales-lead-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/lead-list/LeadList.vue'),
    meta: {
      pageTitle: 'Sales',
      breadcrumb: [
        {
          text: 'Sales Lead',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales/lead-configuration/:id',
    name: 'sales-lead-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/lead-list/LeadConfiguration.vue'),
    meta: {
      pageTitle: 'Sales',
      breadcrumb: [
        {
          text: 'Lead Configuration',
          active: true,
        },
      ],
    },
  },
]
