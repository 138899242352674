export default [
  {
    path: '/users/user-list',
    name: 'user-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user/user-list/UserList.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/user-configuration',
    name: 'user-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user/user-configuration/UserConfiguration.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/users/user-configuration/:id',
    name: 'user-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user/user-configuration/UserConfiguration.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/users/settings',
    name: 'user-settings',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user/user-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'User Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/settings/:id',
    name: 'user-settings-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user/user-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'User Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/password/:id',
    name: 'user-password-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user/user-configuration/UserPassword.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/users/myprofile',
    name: 'my-profile',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user/user-profile/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'My Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/profile/:id',
    name: 'user-profile-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/user/user-profile/Profile.vue'),
    meta: {
      breadcrumb: [],
    },
  },
  {
    path: '/settings/faq',
    name: 'settings-faq',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },
]
