// eslint-disable-next-line import/prefer-default-export
export const CONTENT = {
  BASE: '/contents',
  BY_ID: id => `/contents/${id}`,
  CONTENT_FREQUENCIES: 'content-frequencies',
  CONTENT_STATUSES: 'contentstatuses',
  CONTENT_TYPES: 'content-types',
  INVITATIONS: id => `/contents/${id}/send-invitation-to-all-users`,
  REMINDERS: id => `/contents/${id}/send-reminders-to-unanswered-users`,
  BY_COMPANY_ID: id => `/contents/company/${id}`,
  TEMPALTES: '/contents/templates',
  BY_PROJECT_ID: id => `/contents/project/${id}`,
}
