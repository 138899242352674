// eslint-disable-next-line import/prefer-default-export
export const PROJECT = {
  BASE: '/projects',
  BY_ID: id => `/projects/${id}`,
  BY_COMPANY_ID: id => `/projects/company/${id}`,
  PROJECT_USER: '/projectusers',
  PROJECT_USER_BY_ID: id => `/projectusers/${id}`,
  DELETE_PROJECT_USER_BY_ID: (userId, projectId) => `/projectusers/${userId}/${projectId}`,
  INVITATIONS: id => `/projects/${id}/send-invitation-to-all-users`,
  ALL_COMPANY_USERS_PROJECTS: params => `/projects/${params.id}/companies/${params.company_id}/add-all-users`,
}
